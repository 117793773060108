import { Fragment, useState } from 'react'
import { useStore } from '@nanostores/react'
import { navbarToggleLocked } from '@act/lib/src/store'
import { Dialog, Transition } from '@headlessui/react'

import { classNames } from '@act/lib/src/utils'
import { AHLogo, AssessmentsIcon, HomeIcon, LayerIcon, MatchIcon, MenuIcon, SettingsIcon, TeamsIcon, XIcon } from '../Icons'
import { LogoutButton } from '../Buttons'

export default function Navbar ( { path }: { path: string } ) {
    const navigation = [
        { name: 'Home', href: '/', icon: HomeIcon, current: path === '/', comingSoon: false },
        { name: 'Projects', href: '/cohort', icon: TeamsIcon, current: path.includes( '/cohort' ), comingSoon: false },
        { name: 'Match', href: undefined, icon: MatchIcon, current: path === '/match', comingSoon: true },
        { name: 'Assessments', href: undefined, icon: AssessmentsIcon, current: path === '/assessments', comingSoon: true },
        { name: 'Layer', href: undefined, icon: LayerIcon, current: path === '/layer', comingSoon: true },
        { name: 'Settings', href: undefined, icon: SettingsIcon, current: path === '/settings', comingSoon: true },
    ]
    const [ mobileSidebarOpen, setMobileSidebarOpen ] = useState( false )

    const $navbarToggleLocked = useStore( navbarToggleLocked )

    return (
        <>
            <Transition.Root show={ mobileSidebarOpen } as={ Fragment }>
                <Dialog
                    as="div"
                    className="fixed inset-0 flex z-40 lg:hidden"
                    onClose={ setMobileSidebarOpen }
                >
                    <Transition.Child
                        as={ Fragment }
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={ Fragment }
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={ () => setMobileSidebarOpen( false ) }
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <a href="/home">
                                        <img
                                            src="/ah-logo.svg"
                                            className="logo"
                                            alt="Act House logo"
                                        />
                                    </a>
                                </div>
                                <nav aria-label="Sidebar" className="mt-5">
                                    <div className="px-2">
                                        {navigation.map( item => (
                                            <a
                                                key={ item.name }
                                                href={ item.href }
                                                className={ classNames(
                                                    item.current
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                                                ) }
                                            >
                                                <item.icon
                                                    className={ classNames(
                                                        item.current
                                                            ? 'text-gray-500'
                                                            : 'text-gray-400 group-hover:text-gray-500',
                                                        'mr-4 h-6 w-6',
                                                    ) }
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </a>
                                        ) )}
                                    </div>
                                </nav>
                            </div>
                            <div className="flex-shrink-0 flex border-t border-gray-200 p-6 justify-center">
                                <LogoutButton />
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:flex-shrink-0 h-[calc(100%_-_40px)] group transition-all hover:w-[280px] hover:shadow-xl hover:border-r-[3px] hover:border-[#0000000f] flex-col min-h-0 border-r border-gray-200 overflow-y-auto absolute bg-white z-10 pb-10">
                <a href='/' className="flex my-6 place-self-center w-fit group-hover:self-start group-hover:ml-6">
                    <AHLogo size={ 32 } />
                </a>
                <nav className="transition-all flex flex-col flex-auto" aria-label="Sidebar">
                    {navigation.map( ( item ) => {
                        return (
                            <Fragment key={ item.name }>
                                {!item.comingSoon && (
                                    <a
                                        href={ item.href }
                                        className={ classNames(
                                            item.current
                                                ? 'font-medium text-red bg-light-gray'
                                                : 'text-black-coral hover:bg-gray-50 hover:text-gray-900 font-medium',
                                            'group/a flex rounded-md relative items-end transition-all pl-2.5 pr-[13px] py-2 ml-4 mr-[17px] mb-0.5',
                                        ) }
                                    >
                                        <item.icon
                                            key={ `${ item.name } icon` }
                                            className={ classNames(
                                                item.current
                                                    ? 'text-red'
                                                    : 'text-black-coral group-hover/a:text-gray-500',
                                                'h-6 w-6',
                                            ) }
                                            aria-hidden="true"
                                        />
                                        <span className={ `group-hover:flex transition-all ml-3 ${ $navbarToggleLocked ? 'flex' : 'hidden' } ` }>
                                            {item.name}
                                        </span>
                                    </a>
                                )}</Fragment>
                        )
                    },
                    ) }
                    <div className="group-hover:my-2.5 group-hover:border-y justify-center transition-all text-manatee">
                        <div className='hidden group-hover:flex mt-1.5 ml-4 p-2.5 text-xs text-manatee font-medium font-poppins transition-all'>
                            Coming soon
                        </div>
                        {navigation.map( ( item ) => {
                            return (
                                <Fragment key={ item.name }>
                                    {item.comingSoon && (
                                        <a
                                            href={ item.href }
                                            className='cursor-default text-manatee group/a flex rounded-md relative items-end transition px-2.5 py-2 ml-4 mr-[17px] font-medium mb-0.5'
                                        >
                                            <item.icon
                                                key={ `${ item.name } icon` }
                                                className='text-manatee h-6 w-6'
                                            />
                                            <span className={ `group-hover:flex transition-all ml-3 ${ $navbarToggleLocked ? 'flex' : 'hidden' } ${ item.comingSoon ? 'hover:hidden' : '' } ` }>
                                                {item.name}
                                            </span>

                                        </a>
                                    )}
                                </Fragment>
                            )
                        },
                        )}
                    </div>
                </nav>
                <div className="flex-shrink-0 flex my-8 ml-8 group-hover:ml-8 group-hover:justify-start transition-all">
                    <LogoutButton />
                </div>
            </div>
            <div className="md:hidden">
                <div className="flex items-center justify-between border-b border-gray-200 px-4 py-1.5">
                    <a href='/'>
                        <img src="/ah-logo.svg" className="logo" alt="Act House logo" />
                    </a>
                    <div>
                        <button
                            type="button"
                            className="-mr-3 h-8 w-8 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                            onClick={ () => setMobileSidebarOpen( true ) }
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
